import React from 'react'

function OurCommunity() {
  return (
    <div>
      <h1>Our Community</h1>
    </div>
  )
}

export default OurCommunity;
