import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div
        id="carouselExample"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="./assets/images/coding_languages.webp"
              className="d-block w-100"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h3>Your First Slide Title</h3>
              <p>Here is some description for the first slide.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./assets/images/scaled.jpg"
              className="d-block w-100"
              alt="Second slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h3>Second Slide Title</h3>
              <p>Here is some description for the second slide.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./assets/images/scaled.jpg"
              className="d-block w-100"
              alt="Third slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h3>Third Slide Title</h3>
              <p>Here is some description for the third slide.</p>
            </div>
          </div>
        </div>

        <div className="banner_sld_btn">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
            aria-label="Previous"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
            aria-label="Next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
      <section className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 about_content_box">
              <div className="about_ttl">
                <h5>Welcome to</h5>
                <img src="assets/images/deepakweb.png" />
              </div>
              <div className="about_content">
                <p>
                  Deepak Web IT Solution & Services is a trusted digital
                  marketing company headquartered in India, with a sales center
                  in the USA. Since our inception as an offline business in
                  2006, we have been committed to delivering high-quality,
                  transparent, and customer-focused services. In 2008, we
                  embraced the digital era, expanding our services to the online
                  space to help businesses of all sizes thrive in an
                  ever-changing digital landscape.
                </p>
                <h6>Our Mission</h6>
                <p>
                  Our mission is to empower businesses by enhancing their online
                  presence and driving meaningful growth. With a team of
                  experienced professionals equipped with Google certifications
                  and extensive industry expertise, we deliver tailored
                  strategies and innovative solutions that meet the unique needs
                  of every client.
                </p>
                <h6>Let’s Build Your Online Success Together.</h6>
                <p>
                  Partner with Deepak Web IT Solution & Services today and take
                  the first step toward transforming your digital presence.
                  Whether you need help with SEO, social media, or a complete
                  website overhaul, we’ve got you covered.
                </p>
                <p><Link className="bdy_lnk" to="/contact-us">Contact Me</Link> to start your journey toward online growth and success.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 about_hmp_pic">
              <img src="assets/images/1730600305723.png" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
