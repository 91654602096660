import React from 'react'

function Videos() {
  return (
    <div>
      <h1>Videos</h1>
    </div>
  )
}

export default Videos;
