import React, { useState } from "react";

function Register() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleForm = (event) => {
        event.preventDefault();
        console.log(formData);
    };

    return(
        <>
             <div className="reg_form">

             <form onSubmit={handleForm}>
                    <label htmlFor="fname">Name</label>
                    <br/>
                    <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} />
                    <br/>
                    <label htmlFor="email">Email</label>
                    <br/>
                    <input type="email"  name="email" id="email" value={formData.email} onChange={handleChange} />
                    <br/>
                    <label htmlFor="password">Password</label>
                    <br/>
                    <input  type="password" name="password" id="password" value={formData.password} onChange={handleChange} />
                    <br />
                    <input type="submit" name="btn" value={"Signup"} />
                </form>
             </div>
        </>
    )
}

export default Register;