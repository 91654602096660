import React from 'react'

function AboutUs() {
  return (
    <div>
      <h1>About Us</h1>
      {/* https://techsesh.co/join-community */}
    </div>
  )
}

export default AboutUs;
