import React from 'react';
import { BrowserRouter, Form, Route, Routes } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import OurCommunity from './OurCommunity';
import MyJourney from './MyJourney';
import LifeStyle from './LifeStyle';
import Videos from './Videos';
import Blog from './Blog';
import ContactUs from './ContactUs';
import Register from './Register';


function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/life-style' element={<LifeStyle />} />
        <Route path='/our-community' element={<OurCommunity />} />
        <Route path='/my-journey' element={<MyJourney />} />
        <Route path='/videos' element={<Videos/>} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/signup' element={<Register />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App;
