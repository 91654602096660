import React from "react";
import { Link } from "react-router-dom";
import "./HeaderFooter.css";

function Footer() {
  return (
    <>
      <section className="footer-section">
        <div className="container">
          <div className="row ft_sect">
            {/* Dynamically render the three sections */}
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="col-sm-12 col-md-3" key={index}>
                <h5>Quick Link</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link className="nav-link p-0 text-muted" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link className="nav-link p-0 text-muted" to="/features">
                      Features
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link className="nav-link p-0 text-muted" to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link className="nav-link p-0 text-muted" to="/faqs">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link className="nav-link p-0 text-muted" to="/about">
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            ))}
            <div className="col-md-4 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.1923045777885!2d85.72808147582688!3d20.250857814040533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a84bdd47499d%3A0x6dc837ef187a4c6!2sJagannath%20Warriors%20Residency!5e0!3m2!1sen!2sin!4v1732388257437!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="col-md-4 col-sm-12">
              <form>
                <h5>Subscribe to our newsletter</h5>
                <p>Monthly digest of what's new and exciting from us.</p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                  />
                  <button className="btn btn-primary" type="submit">
                    Subscribe
                  </button>
                </div>
              </form>
              <div className="follow_us_ft">
              <h4>Follow me</h4>
              <div class="social-media-icons">
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-youtube"></i>
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://github.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-github"></i>
                </a>
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-icon"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 border-top">
            <p className="coppyright">© 2022 DeepakWeb, Inc. All rights reserved.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
