import React from 'react'

function Blog() {
  return (
    <div>
      <h1>Blog</h1>
    </div>
  )
}

export default Blog;
